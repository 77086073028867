import payload_plugin_qLmFnukI99 from "/builds/marketing/website-2.0/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_4sVQNw7RlN from "/builds/marketing/website-2.0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/builds/marketing/website-2.0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/builds/marketing/website-2.0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/builds/marketing/website-2.0/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/builds/marketing/website-2.0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/builds/marketing/website-2.0/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/builds/marketing/website-2.0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/builds/marketing/website-2.0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/builds/marketing/website-2.0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/builds/marketing/website-2.0/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/builds/marketing/website-2.0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_VdOeuw25Mb from "/builds/marketing/website-2.0/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/builds/marketing/website-2.0/.nuxt/sentry-client-config.mjs";
import plugin_tbFNToZNim from "/builds/marketing/website-2.0/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_hBLWEPkA3b from "/builds/marketing/website-2.0/node_modules/@storyblok/nuxt/dist/runtime/plugin.js";
import floating_vue_EIcJ7xiw0h from "/builds/marketing/website-2.0/.nuxt/floating-vue.mjs";
import youtube_client_mqm20bbARB from "/builds/marketing/website-2.0/plugins/youtube.client.ts";
export default [
  payload_plugin_qLmFnukI99,
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  sentry_client_VdOeuw25Mb,
  sentry_client_config_o34nk2sJbg,
  plugin_tbFNToZNim,
  plugin_hBLWEPkA3b,
  floating_vue_EIcJ7xiw0h,
  youtube_client_mqm20bbARB
]